const theme = {
  name: 'custom-theme',
  tokens: {
    colors: {
      background: {
        primary: { value: 'var(--surface-ground)' },
        secondary: { value: 'var(--surface-card)' },
      },
      font: {
        interactive: { value: 'var(--text-color)' },
        primary: { value: 'var(--text-color)' },
        secondary: { value: 'var(--text-color-secondary)' },
      }
    },
    components: {
      authenticator: {
        modal: {
          backgroundColor: { value: 'var(--surface-card)' },
          borderRadius: { value: 'var(--border-radius)' },
          boxShadow: { value: 'var(--card-shadow)' }
        },
        container: {
          backgroundColor: { value: 'var(--surface-card)' },
          borderRadius: { value: 'var(--border-radius)' },
          boxShadow: { value: 'var(--card-shadow)' },
          padding: { value: '2rem' },
          width: { value: '100%' },
          maxWidth: { value: '400px' }
        },
        header: {
          color: { value: 'var(--text-color)' },
          fontSize: { value: '1.5rem' },
          textAlign: { value: 'center' }
        },
        form: {
          backgroundColor: { value: 'transparent' }
        },
        button: {
          backgroundColor: { value: 'transparent' },
          color: { value: 'var(--text-color)' },
          borderColor: { value: 'var(--surface-border)' },
          borderWidth: { value: '1px' },
          borderRadius: { value: 'var(--border-radius)' },
          padding: { value: '0.75rem 1.25rem' },
          fontSize: { value: '1rem' },
          transition: { value: 'background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s' },
          _hover: {
            backgroundColor: { value: 'rgba(255, 255, 255, 0.03)' },
            borderColor: { value: 'var(--surface-border)' },
            color: { value: 'var(--text-color)' }
          },
          _active: {
            backgroundColor: { value: 'rgba(255, 255, 255, 0.03)' },
            borderColor: { value: 'var(--surface-border)' },
            color: { value: 'var(--text-color)' }
          },
          _focus: {
            outline: { value: '0 none' },
            outlineOffset: { value: '0' },
            boxShadow: { value: '0 0 0 0.2rem var(--surface-border)' }
          }
        }
      }
    }
  }
};

export default theme;