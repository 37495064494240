import React, { useState } from 'react';
import { API } from 'aws-amplify';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Message } from 'primereact/message';
import { getApiConfig } from '../config/api';
import './ChatBox.css';

function ChatBox() {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [loading, setLoading] = useState(false);
  const apiConfig = getApiConfig();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await API.post('API', apiConfig.endpoints.chat, {
        body: { question }
      });
      setAnswer(response.answer);
    } catch (error) {
      console.error('Error fetching chat response:', error);
      setAnswer('Error: Unable to get a response. Please try again.');
    }
    setLoading(false);
  };

  return (
    <Card className="chat-box">
      <form onSubmit={handleSubmit} className="p-fluid">
        <div className="p-inputgroup">
          <InputText
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Ask a question..."
            disabled={loading}
          />
          <Button
            type="submit"
            icon={loading ? "pi pi-spin pi-spinner" : "pi pi-send"}
            disabled={loading}
            label={loading ? "Sending..." : "Send"}
          />
        </div>
      </form>
      {answer && (
        <div className="answer mt-3">
          <Message severity="info" text={answer} className="w-full" />
        </div>
      )}
    </Card>
  );
}

export default ChatBox;