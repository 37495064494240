const apiConfig = {
  development: {
    baseUrl: process.env.REACT_APP_API_URL || 'http://localhost:4566',
    endpoints: {
      chat: '/restapis/local-api/prod/_user_request_/chat'
    }
  },
  production: {
    baseUrl: 'https://api.gkohut.click',
    endpoints: {
      chat: '/chat'
    }
  }
};

export const getApiConfig = () => {
  const environment = process.env.REACT_APP_ENV || 'production';
  return apiConfig[environment];
}; 