import React from 'react';
import { Menubar } from 'primereact/menubar';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';

function Navbar({ signOut, userEmail }) {
  const navigate = useNavigate();

  const items = [
    {
      label: 'Home',
      icon: 'pi pi-home',
      command: () => navigate('/')
    },
    {
      label: 'Chat',
      icon: 'pi pi-comments',
      command: () => navigate('/chat')
    }
  ];

  const end = (
    <div className="navbar-end">
      <span className="user-email">{userEmail}</span>
      <Button
        icon="pi pi-sign-out"
        label="Logout"
        severity="secondary"
        outlined
        onClick={signOut}
      />
    </div>
  );

  return (
    <div className="navbar-container">
      <Menubar model={items} end={end} />
    </div>
  );
}

export default Navbar;
