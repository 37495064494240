import React from 'react';
import ChatBox from './ChatBox';
import './Chat.css';

function Chat() {
  return (
    <main className="chat-page">
      <ChatBox />
    </main>
  );
}

export default Chat; 