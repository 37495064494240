import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Authenticator, ThemeProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import "primereact/resources/themes/lara-dark-indigo/theme.css";     // theme
import "primereact/resources/primereact.min.css";                    // core css
import "primeicons/primeicons.css";                                  // icons
import './App.css';
import Home from './components/Home';
import Welcome from './components/Welcome';
import Chat from './components/Chat';
import theme from './theme';

Amplify.configure({
  Auth: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_W0NXCJc3j',
    userPoolWebClientId: '17qhioeftlmc5q0htnnhir5f89',
    oauth: {
      domain: 'auth.gkohut.click',
      scope: ['email', 'openid', 'profile'],
      redirectSignIn: 'https://gkohut.click/callback',
      redirectSignOut: 'https://gkohut.click',
      responseType: 'code'
    }
  },
  API: {
    endpoints: [
      {
        name: 'API',
        endpoint: 'https://api.gkohut.click',
        region: 'eu-central-1',
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Amplify.Auth.currentSession()).getIdToken().getJwtToken()}` }
        }
      }
    ]
  }
});

function App() {
  return (
    <Router>
      <div className="App">
        <div className="layout-wrapper">
          <ThemeProvider theme={theme}>
            <Authenticator className="authenticator-container" hideSignUp={true}>
              {({ signOut, user }) => (
                <Routes>
                  <Route path="/" element={<Home signOut={signOut} user={user} />}>
                    <Route index element={<Welcome />} />
                    <Route path="chat" element={<Chat />} />
                    <Route path="*" element={<Navigate to="/" />} />
                  </Route>
                </Routes>
              )}
            </Authenticator>
          </ThemeProvider>
        </div>
      </div>
    </Router>
  );
}

export default App;
