import React from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import './Welcome.css';

function Welcome() {
  const navigate = useNavigate();

  const handleStartClick = () => {
    navigate('/chat');
  };

  return (
    <main className="welcome-page">
      <Card className="welcome-card">
        <h1>Üdv a weboldalon!</h1>
        <p className="welcome-text">
          Ez egy orvoskereső chatbot. Kattints, hogy megkeresd a problémádhoz legalkalmasabb orvost.
        </p>
        <div className="text-center">
          <Button 
            label="Start" 
            onClick={handleStartClick}
            severity="secondary"
            outlined
          />
        </div>
      </Card>
    </main>
  );
}

export default Welcome;