import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import './App.css';
import Home from './components/Home';
import Welcome from './components/Welcome';
import Chat from './components/Chat';
import { getApiConfig } from './config/api';

const apiConfig = getApiConfig();

Amplify.configure({
  API: {
    endpoints: [
      {
        name: 'API',
        endpoint: apiConfig.baseUrl,
        region: 'eu-central-1',
        custom_header: async () => {
          return { 'Content-Type': 'application/json' }
        }
      }
    ]
  }
});

function App() {
  const mockUser = {
    attributes: {
      email: 'dev@local.test'
    }
  };
  const mockSignOut = () => console.log('Sign out clicked');

  return (
    <Router>
      <div className="App">
        <div className="layout-wrapper">
          <Routes>
            <Route path="/" element={<Home signOut={mockSignOut} user={mockUser} />}>
              <Route index element={<Welcome />} />
              <Route path="chat" element={<Chat />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
