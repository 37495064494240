import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './Navbar';
import './Home.css';

function Home({ signOut, user }) {
  return (
    <div className="Home">
      <Navbar 
        signOut={signOut} 
        userEmail={user.attributes.email} 
      />
      <Outlet />
    </div>
  );
}

export default Home;